<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="addContainerNotesForm"
        ref="addContainerNotesForm"
        class="w-100 pt-6"
        v-model="isFormValid"
        @submit.prevent="submitContainerNotesForm"
      >
        <v-row>
          <v-col cols="12">
            <v-textarea
              v-model="containerNotes"
              outlined
              :rows="3"
              auto-grow
              label="Notatka do kontenera"
              :rules="[rules.required]"
              hide-details
              placeholder="Wprowadź notatkę"
            />
          </v-col>
          <v-col cols="12">
            <v-checkbox
              v-model="addTask"
              label="Zaplanuj kolejny kontakt"
              hide-details
              class="mt-0"
            />
          </v-col>

          <template v-if="addTask">
            <v-col
              cols="12"
              class="mt-4"
            >
              <v-text-field
                outlined
                label="Zlecający pracownik"
                readonly
                :value="fullName"
              />
            </v-col>

            <v-col cols="12">
              <v-autocomplete
                outlined
                :items="users"
                label="Przypisany pracownik"
                placeholder="Przypisz pracownika"
                v-model="task.assignedUserId"
                :item-text="item => `${item.firstName} ${item.lastName}`"
                item-value="id"
                :rules="[rules.required]"
                append-icon="mdi-chevron-down"
                dense
              />
            </v-col>

            <v-col cols="12">
              <v-text-field
                outlined
                label="Temat"
                v-model="task.topic"
                :rules="[rules.required]"
                placeholder="Wpisz temat"
              />
            </v-col>

            <v-col cols="12">
              <v-menu
                v-model="showDatePicker"
                v-bind="attributes.menu"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    :value="formattedDate"
                    :rules="showDatePicker ? [] : [rules.required]"
                    label="Data realizacji"
                    placeholder="Wybierz datę realizacji"
                    v-bind="{...attrs, ...attributes.textField}"
                    v-on="on"
                  >
                    <template #prepend-inner>
                      <Icon
                        name="date"
                        size="small"
                        class="mr-2"
                      />
                    </template>
                    <template #append>
                      <SwitchDayButtons
                        v-model="task.dueDate"
                        :min="minDate"
                      />
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="task.dueDate"
                  v-bind="attributes.datePicker"
                  :min="minDate"
                  @input="showDatePicker = false"
                />
              </v-menu>
            </v-col>
          </template>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        type="submit"
        form="addContainerNotesForm"
      >
        Dodaj notatkę
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import rules from '../../utils/validators'
import attributes from '../../const/datePickerAttrributes'
import { dateStringFormat } from '../../utils'
import { mapState, mapGetters, mapActions } from 'vuex'
import DateTime from 'luxon/src/datetime'

export default {
  components: {
    DialogFormWrapper
  },
  data () {
    return {
      rules,
      attributes,
      isFormValid: false,
      showDatePicker: false,
      addTask: false,
      containerNotes: '',
      task: {
        dueDate: new Date().toISOString().substr(0, 10),
        topic: 'Informacja o odbiorze kontenera',
        assignedUserId: null,
      },
      isProcessing: false,
    }
  },
  computed: {
    ...mapState({
      container: state => state.layout.dialog.item,
      userId: state => state.auth.user.id,
      users: state => state.users.items,
    }),
    ...mapGetters({
      fullName: 'auth/getFullName'
    }),
    minDate () {
      return DateTime.utc().toISODate()
    },
    formattedDate () {
      if (this.task.dueDate) {
        return dateStringFormat(this.task.dueDate)
      }
      return ''
    }
  },
  mounted () {
    this.getUsers()
    this.task.assignedUserId = this.container.order?.client?.patronId
  },
  methods: {
    ...mapActions({
      addNewTask: 'tasks/addNewItem',
      getUsers: 'users/getItems',
      getContainers: 'containers/getItems',
      updateOrder: 'orders/editItem',
      closeDialog: 'layout/closeDialog'
    }),
    submitContainerNotesForm () {
      this.$refs.addContainerNotesForm.validate()

      if (this.isFormValid) {
        const addContainerNotes = this.updateOrder.bind(this, {
          tableName: 'orders',
          params: { containerNotes: this.containerNotes },
          id: this.container.order.id
        })

        const params = {
          ...this.task,
          orderingUserId: this.userId,
          description: this.containerNotes,
          orderId: this.container.order.id,
          taskType: 'Dowolny'
        }
        const addNewTask = this.addNewTask.bind(this, { params })

        const requests = [addContainerNotes]
        if (this.addTask) requests.push(addNewTask)

        const promises = requests.map((r) => new Promise((resolve) => {
          return r().then(() => {
            resolve()
            if (this.$route.name === 'containers') this.getContainers()
          })
        }))

        this.isProcessing = true
        Promise.all(promises)
          .then(() => {
            this.closeDialog()
          })
          .finally(() => {
            this.isProcessing = false
          })
      }
    }
  }
}
</script>
